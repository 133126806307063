$logo-width: 120px;
$logo-height: 22px;

// default colors
.theme-wrapper {
    --primary: #646567;
    --secondary: #dadada;
    --hover: #b00036;
    --red-light: #e7d3d9;
    --red-dark: #490002;
    --green-light: #d0d8bc;
    --green-dark: #034e0d;
    --primary-font: 'Arial';
    --header-text-color: #646567;
}
